<template>
  <div class="content">
    <div class="top">
      <img class="bg" src="@/assets/store/detail/top.png">
      <div class="wrapper">
        <h2>Quest2无线大空间</h2>
        <h3>超真实、零眩晕、无束缚</h3>
        <div class="video-mini" @click="video('play')">
          <img class="video" src="@/assets/store/detail/mini.webp">
          <div class="tip">
            <img src="data:image/svg+xml;base64,PHN2ZyBjbGFzcz0iaWNvbiIgdmlld0JveD0iMCAwIDEwMjQgMTAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTI4IiBoZWlnaHQ9IjEyOCI+PHBhdGggZD0iTTUxMiAwQzIzMC40IDAgMCAyMzAuNCAwIDUxMnMyMzAuNCA1MTIgNTEyIDUxMiA1MTItMjMwLjQgNTEyLTUxMlM3OTMuNiAwIDUxMiAwem0wIDkzOC42NjdjLTIzNC42NjcgMC00MjYuNjY3LTE5Mi00MjYuNjY3LTQyNi42NjdTMjc3LjMzMyA4NS4zMzMgNTEyIDg1LjMzM3M0MjYuNjY3IDE5MiA0MjYuNjY3IDQyNi42NjctMTkyIDQyNi42NjctNDI2LjY2NyA0MjYuNjY3eiIgZmlsbD0iI2ZmZiIvPjxwYXRoIGQ9Ik02ODYuOTMzIDQ2OS4zMzNsLTIyMS44NjYtMTI4Yy0zNC4xMzQtMTcuMDY2LTcyLjUzNCA0LjI2Ny03Mi41MzQgNDIuNjY3djI1NmMwIDM4LjQgMzguNCA1OS43MzMgNzIuNTM0IDQyLjY2N2wyMjEuODY2LTEyOGMyOS44NjctMjEuMzM0IDI5Ljg2Ny02NCAwLTg1LjMzNHoiIGZpbGw9IiNmZmYiLz48L3N2Zz4=" class="icon-play">
            <span class="tip-text">视频介绍</span>
          </div>
        </div>
      </div>
    </div>

    <div class="main">
      <h3 class="h3-1">Quest无线大空间方案</h3>

      <p class="p-1">Quest无线大空间方案是造梦科技自主研发的无线大空间系统，目前已更新至第三代方案，采用2020年最新发布的Oculus Quest2头显，突破性的解决了VR真实移动和同场交互的行业难题。方案采用最高端的VR头盔、大场地追踪算法、精确动捕系统，为玩家营造最真实的沉浸感和交互体验，为多人玩家提供超大空间的自由移动、对战，真实距离交互。</p>

      <h4 class="h4-1">赚钱神器，开店必上</h4>

      <section class="section-1">
        <div class="left-img">
          <img src="@/assets/store/detail/left.png">
        </div>
        <div class="right-content">
          <p>线下零售价：88元/人次<br>占地面积：6m*6m<br>最多支持：4人<br>软件配套：<br>1.造梦科技独家研发—Quest无线串流方案<br>2.多款独家大空间游戏<br>设备配套：<br>头显*4：OCULUS QUEST2<br>路由*1：华硕 AX11000<br>主机*4：CPU i7 9700显卡 NVIDIA 2060 内存 8G<br>主控*1：CPU i5 7500显卡 NVIDIA 1660 内存 8G</p>
        </div>
      </section>

      <el-carousel class="section-2" :interval="4000" type="card" height="350px">
        <el-carousel-item v-for="i in 3" :key="i">
          <!-- <el-image style="height:100%;width:100%" :src="require(`@/assets/store/imgs/${i}.png`)" fit="cover"></el-image> -->
          <el-image style="height:100%;width:100%" :src="$hwcdnLink(`imagehost/vrhome_store/${i}.png`)" fit="cover"></el-image>
        </el-carousel-item>
      </el-carousel>

      <h4 class="h4-2">无线大空间内容</h4>

      <div class="section-3">
        <swiper class="swiper-no-swiping" :options="wxdkjnrOptions">
          <swiper-slide class="games-group" v-for="(A,a) in ['0,4','4,8']" :key="a">
            <div class="container">
              <template v-for="(B,b) in wxdkjnr.slice(A.split(',')[0], A.split(',')[1])">
                <div class="game" :style="{backgroundImage: `url(${B.cover})`}" :key="b">
                  <div class="title">《{{B.title}}》</div>
                  <div class="context">{{B.context}}</div>
                </div>
              </template>
            </div>
          </swiper-slide>
        </swiper>
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
      </div>

      <h4 class="h4-3">专业打造VR体验馆</h4>

      <div class="section-4">
        <ul class="tags">
          <li class="tag" :class="{active: a===zydzvrtyg.current}" v-for="(A,a) in zydzvrtyg.list" :key="a" @mouseover="zydzvrtyg.current=a">{{A.label}}</li>
        </ul>
        <p class="context">{{zydzvrtyg.list[zydzvrtyg.current].context}}</p>
      </div>

      <h4 class="h4-4">Quest无线大空间和背包大空间对比</h4>

      <img class="img-1" src="@/assets/store/detail/table.png">

      <div class="section-5">
        <div class="item" v-for="(A,a) in duibi" :key="$randomKey(a)">
          <div class="top">
            <h3>{{A.title}}</h3>
            <span class="subtitle">{{A.subtile}}</span>
            <p class="intro" v-html="A.intro"></p>
          </div>
          <div class="tags">
            <span class="title">包含</span>
            <ul class="list">
              <li class="item" v-for="(B,b) in A.tags" :key="$randomKey(b)">
                <span>{{B.label}}</span>
                <i class="iconfont icon-gou3" v-if="B.type=='icon'"></i>
                <span v-else>{{B.type}}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <p class="p-2">了解更多关于Oculus Quest2以及无线大空间方案，享受真正无束缚体验，欢迎您随时联系</p>

      <section class="advisory">
        <div class="advisory-content">
          <div class="icon-box">
            <i class="iconfont icon-dianhua"></i>
          </div>
          <div class="info">
            <h3>合作咨询</h3>
            <p>联系人：张先生<br>电话：18759946965<br>微信：zamervr</p>
          </div>
          <div class="weixin">
            <img src="@/assets/store/qrcode.png" alt="" class="qrcode">
            <p class="tip">欢迎扫码咨询</p>
          </div>
        </div>
      </section>

    </div>

    <div class="video-preview" v-show="showVideo">
      <div id="video"></div>
      <img src="data:image/svg+xml;base64,PHN2ZyBjbGFzcz0iaWNvbiIgdmlld0JveD0iMCAwIDEwMjQgMTAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTI4IiBoZWlnaHQ9IjEyOCI+PHBhdGggZD0iTTUwOS44NjcgMzJDMjQ1LjMzMyAzMiAzMiAyNDcuNDY3IDMyIDUxMnMyMTMuMzMzIDQ4MCA0NzcuODY3IDQ4MCA0NzcuODY2LTIxNS40NjcgNDc3Ljg2Ni00ODBTNzc0LjQgMzIgNTA5Ljg2NyAzMnptMCA4OTZDMjgxLjYgOTI4IDk2IDc0Mi40IDk2IDUxMlMyODEuNiA5NiA1MDkuODY3IDk2czQxMy44NjYgMTg1LjYgNDEzLjg2NiA0MTYtMTg1LjYgNDE2LTQxMy44NjYgNDE2eiIgZmlsbD0iI2ZmZiIvPjxwYXRoIGQ9Ik02OTMuMzMzIDMzMC42NjdjLTEyLjgtMTIuOC0zMi0xMi44LTQ0LjggMEw1MTIgNDY3LjIgMzc1LjQ2NyAzMzAuNjY3Yy0xMi44LTEyLjgtMzItMTIuOC00NC44IDAtMTIuOCAxMi44LTEyLjggMzIgMCA0NC44TDQ2Ny4yIDUxMiAzMzAuNjY3IDY0OC41MzNjLTEyLjggMTIuOC0xMi44IDMyIDAgNDQuOCA2LjQgNi40IDE0LjkzMyA4LjUzNCAyMy40NjYgOC41MzRzMTcuMDY3LTIuMTM0IDIzLjQ2Ny04LjUzNEw1MTQuMTMzIDU1Ni44bDEzNi41MzQgMTM2LjUzM2M2LjQgNi40IDE0LjkzMyA4LjUzNCAyMy40NjYgOC41MzRzMTcuMDY3LTIuMTM0IDIzLjQ2Ny04LjUzNGMxMi44LTEyLjggMTIuOC0zMiAwLTQ0LjhMNTU2LjggNTEybDEzNi41MzMtMTM2LjUzM2MxMi44LTEyLjggMTIuOC0zMiAwLTQ0Ljh6IiBmaWxsPSIjZmZmIi8+PC9zdmc+" class="close" @click="video('close')">
    </div>
  </div>
</template>

<script>
import Chimee from "chimee";
import hls from "chimee-kernel-hls";
let chimee;

export default {
  data() {
    return {
      wxdkjnrOptions: {
        spaceBetween: 20,
        cssMode: true,
        mousewheel: false,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      wxdkjnr: [
        {
          cover: this.$hwcdnLink("imagehost/vrhome_zaomeng_space/game_1.png"),
          title: "义庄派对大空间",
          context:
            "《义庄派对大空间》是一款恐怖刺激的VR游戏。具有中国特色恐怖元素，以千年僵尸和茅山道长为人物原型，常年占据中国VR恐怖游戏榜前5，累计体验超过200W人次的《义庄派对》，终于迎来了4人版本！童子尿，铜钱镖，灭尸符，定身符，四人征战，所向披靡！新角色，新剧情，新方式，带来全新感受！",
        },
        {
          cover: this.$hwcdnLink("imagehost/vrhome_zaomeng_space/game_2.png"),
          title: "梦境：起源",
          context:
            "一艘来自遥远破败星球的飞船，降临到了这颗他们命名为“Dreamland”的新星球。但Dreamland作为一颗魔法星球，遍布着战斗力不弱于那些入侵者的强大魔法师。入侵者们在殖民的初期，就遭遇了魔法师们激烈的抵抗...",
        },
        {
          cover: this.$hwcdnLink("imagehost/vrhome_zaomeng_space/game_3.png"),
          title: "太阳神",
          context:
            "多年前, 一个未知的星球被地球发出的人工智能“Helios”发现并占领。你和你的队友组成人类探勘队，必须消灭变质的“Helios”, 并在星球主塔设置核子弹。将核子弹藏入主塔并激活核子弹能源盾以吸引“Helios”进入星球主塔。 在Helios进入星球主塔后, 人类探勘队必须在核子弹引爆前登上逃脱船成功远离星球。",
        },
        {
          cover: this.$hwcdnLink("imagehost/vrhome_zaomeng_space/game_4.png"),
          title: "鬼屋惊魂",
          context:
            "《鬼屋惊魂》：是一款恐怖刺激的探险类VR游戏。离奇死亡的一家三口、诡异的实验室、阴森的焚尸炉、古老的提灯，面具下隐藏的是什么？让我们一起去探险揭开这“疯狂派对”的神秘真相吧！",
        },
        {
          cover: this.$hwcdnLink("imagehost/vrhome_zaomeng_space/game_5.png"),
          title: "糖果奇兵",
          context:
            "《糖果奇兵》这个游戏是一款射击类VR游戏。游戏画面亮丽、场景丰富。游戏的主人公是一对圆乎乎的胖兔子。可爱单纯的兔子们化身为“伟大的守卫者”来保卫糖果镇。面对来势汹汹的坏蛋们，守卫者要用自己的武器‘雪球’，把的敌人消灭掉快来一起守卫糖果镇，成为真正的糖果英雄吧！",
        },
        {
          cover: this.$hwcdnLink("imagehost/vrhome_zaomeng_space/game_6.png"),
          title: "尸人派对",
          context:
            "《尸人派对》是一款恐怖刺激的探险类VR游戏。一帮热情澎湃的年轻人，为给老铁一次惊险刺激的生日party，精心准备了一个游戏。离奇死亡的一家三口、诡异的实验室、阴森的焚尸炉、古老的提灯，面具下隐藏的是什么？是血腥的杀戮？还是故作恐怖？ 这究竟是个恶作剧还是真的遇见了鬼？结局究竟怎样？让我们一起去探险揭开这“疯狂派对”的神秘真相吧！",
        },
        {
          cover: this.$hwcdnLink("imagehost/vrhome_zaomeng_space/game_7.png"),
          title: "龙焱特攻：利刃出鞘",
          context:
            "《龙焱特攻：利刃出鞘》没有什么比真正的战场更能体现英雄的荣耀，也没有什么比激烈甚至惨烈的战争更能激发一个人的斗志。英雄们即将手持利刃披挂出征，在枪林弹雨的突袭下捍卫“龙焱特攻”的荣耀。子弹已上膛，利刃已出鞘，朋友该你们上场了！！！",
        },
        {
          cover: this.$hwcdnLink("imagehost/vrhome_zaomeng_space/game_8.png"),
          title: "星际舰队-入侵",
          context:
            "《星际舰队-入侵》是一款伴随同名连载小说改编的星际探险类大空间多人互动游戏。现实与虚拟 1:1 同步，游戏可适配 50-100 平米的场馆，适用所有年龄段的玩家。",
        },
      ],
      zydzvrtyg: {
        list: [
          {
            label: "超真实",
            context:
              "大空间多人VR互动最大的优势在于可以在虚拟场景中真实行走，大幅提升VR体验馆的可玩性",
          },
          {
            label: "零眩晕",
            context:
              "大空间解决了传统VR用手柄移动导致眩晕的问题，真实行走真实交互",
          },
          {
            label: "无束缚",
            context:
              "大空间摆脱了原有VR体验的线缆束缚，玩家可以无拘无束的在虚拟世界中自由的交互",
          },
          {
            label: "高门槛",
            context:
              "大空间对于场地的要求高，玩家们只有在线下店才能体验到，为体验馆长期可持续发展提供保障",
          },
          {
            label: "最低价",
            context:
              "造梦以最大的诚意，最低的成本价对外推广，为体验馆升级Quest大空间提供最好的机会",
          },
        ],
        current: 0,
      },
      duibi: [
        {
          title: "迷你公寓店",
          subtile: "(60平米)",
          intro: "投入低<br>坪效高<br>空间利用率高<br>回本周期短",
          tags: [
            { label: "设备", type: "icon" },
            { label: "基站", type: "icon" },
            { label: "吧台", type: "icon" },
            { label: "绿幕区", type: "icon" },
            { label: "休闲区", type: "icon" },
            { label: "游戏区域（双人包间）", type: "icon" },
          ],
        },
        {
          title: "标准大空间公寓店",
          subtile: "(100平米)",
          intro:
            "大空间带来产品差异化<br>同行业提升竞争力<br>休息区优化方案<br>可减少等待顾客的流失率",
          tags: [
            { label: "设备", type: "icon" },
            { label: "基站", type: "icon" },
            { label: "吧台", type: "icon" },
            { label: "绿幕区", type: "icon" },
            { label: "休闲区", type: "升级版" },
            { label: "游戏区域（双人包间）", type: "icon" },
            { label: "Quest无线大空间", type: "icon" },
          ],
        },
        {
          title: "综合型大空间公寓店",
          subtile: "(130平米)",
          intro:
            "综合型娱乐空间<br>除正常到店顾客外<br>还可以预约接待团建<br>聚会、包场、生日派对等<br>在淡季提升收入",
          tags: [
            { label: "设备", type: "icon" },
            { label: "基站", type: "icon" },
            { label: "吧台", type: "icon" },
            { label: "绿幕区", type: "icon" },
            { label: "休闲区", type: "定制版" },
            { label: "游戏区域（双人包间）", type: "icon" },
            { label: "Quest无线大空间", type: "icon" },
          ],
        },
      ],

      showVideo: false,
    };
  },
  mounted() {
    chimee = new Chimee({
      wrapper: "#video",
      src: "http://hwcdn.zmvr.com/videohost/vrhome_store_detail/video.m3u8",
      controls: true,
      autoplay: false,
      muted: false,
      loop: true,
      kernels: {
        hls,
      },
    });
  },
  methods: {
    video(status) {
      if (status === "play") {
        this.showVideo = true;
        chimee.play();
      } else if (status === "close") {
        chimee.pause();
        this.showVideo = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding-top: 100px;
}
.top {
  width: 100%;
  height: 800px;
  position: relative;
  .bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .wrapper {
    width: 900px;
    height: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 270px;
    box-sizing: border-box;

    h2,
    h3 {
      line-height: 1;
      margin: 0;
      padding: 0;
      color: #fff;
    }
    h2 {
      margin-bottom: 60px;
      font-size: 60px;
      font-weight: normal;
    }
    h3 {
      margin-bottom: 90px;
      font-size: 40px;
      font-weight: lighter;
    }
    .video-mini {
      border-radius: 100px;
      overflow: hidden;
      position: relative;
      cursor: pointer;
      .video {
        display: block;
        pointer-events: none;
      }
      .tip {
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        pointer-events: none;
        .icon-play {
          width: 30px;
          height: 30px;
          display: block;
        }
        .tip-text {
          font-size: 24px;
          color: #fff;
          margin-left: 40px;
          line-height: 1;
        }
      }
    }
  }
}
.video-preview {
  width: 100%;
  height: 100%;
  z-index: 99999;
  background-color: rgba(#000, 0.95);
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  #video {
    width: 1000px;
    height: 562px;
  }

  .close {
    position: absolute;
    right: 30px;
    top: 30px;
    width: 30px;
    height: 30px;
    display: block;
    cursor: pointer;
  }
}
.main {
  width: 1080px;
  margin: 0 auto;
  margin-top: 120px;
  h3,
  h4 {
    font-weight: normal;
    line-height: 1;
    margin: 0;
    text-align: center;
    color: #1c1e20;
  }
  h3 {
    font-size: 30px;
  }
  h4 {
    font-size: 24px;
  }
  .h3-1 {
    margin-bottom: 78px;
  }
  .p-1 {
    font-size: 18px;
    line-height: 49px;
    text-align: center;
    color: rgba(#1c1e20, 0.8);
    margin-bottom: 120px;
  }
  .h4-1 {
    margin-bottom: 85px;
  }
  .section-1 {
    margin-bottom: 80px;
    display: flex;
    .left-img {
      width: 500px;
      height: 333px;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        position: absolute;
        display: block;
        object-fit: cover;
      }
    }
    .right-content {
      flex: 1;
      background-color: #1c232d;
      padding: 28px 50px;
      p {
        color: #fff;
        font-size: 18px;
        line-height: 30px;
        margin: 0;
      }
    }
  }
  .section-2 {
    margin-bottom: 90px;
  }
  .h4-2 {
    margin-bottom: 60px;
  }
  .section-3 {
    width: 100%;
    height: 244px;
    outline: solid 1px #ccc;
    position: relative;
    margin-bottom: 80px;

    .games-group {
      height: 100%;
    }

    .container {
      display: flex;
      height: 244px;
      color: #fff;

      .game {
        flex: 1;
        height: 100%;
        position: relative;
        background-size: cover;
        background-position: center;
        transition: flex 0.3s;
        cursor: default;

        .title {
          width: 100%;
          padding: 30px 0 15px;
          line-height: 1;
          font-size: 18px;
          text-align: center;
          display: block;
          position: absolute;
          left: 0;
          bottom: 0;
          background-image: linear-gradient(to bottom, transparent, #000);
          transform: translateY(0);
          transition: transform 0.3s;
        }
        .context {
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: rgba(#000, 0.5);
          padding: 30px;
          box-sizing: border-box;
          font-size: 14px;
          line-height: 1.5;
          opacity: 0;
          transition: opacity 0.6s;
        }

        &:hover {
          flex: 1.5;

          .title {
            transform: translateY(100%);
          }
          .context {
            opacity: 1;
          }
        }
      }
    }

    .swiper-button-next,
    .swiper-button-prev {
      width: 30px;
      height: 30px;
      position: absolute;
      left: unset;
      top: unset;
      right: -40px;
      bottom: 0;

      &::after {
        font-size: 20px;
        color: #1c1e20;
      }
    }
    .swiper-button-next {
      bottom: 40px;
    }
  }
  .h4-3 {
    margin-bottom: 80px;
  }
  .section-4 {
    margin-bottom: 80px;
    .tags {
      padding: 0 200px;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      // border-bottom: solid 1px rgba(#323436, .1);
      position: relative;
      &::after {
        content: "";
        width: 100%;
        height: 1px;
        background-color: rgba(#323436, 0.1);
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: -1;
      }
      .tag {
        padding: 16px 10px;
        list-style: none;
        position: relative;
        font-size: 18px;
        color: #323436;
        overflow: hidden;
        cursor: default;
        &.active {
          color: #0edee3;
          &::before {
            content: "";
            width: 100%;
            height: 1px;
            background-color: #0edee3;
            position: absolute;
            left: 0;
            bottom: 0px;
          }
          &::after {
            content: "";
            width: 10px;
            height: 10px;
            background-color: #fff;
            border: solid 1px #0edee3;
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translate(-50%, 50%) rotate(45deg);
          }
        }
      }
    }
    .context {
      text-align: center;
      font-size: 18px;
      color: #323436;
      line-height: 1;
      padding: 40px 0;
      margin: 0;
    }
  }
  .h4-4 {
    margin-bottom: 88px;
  }
  .img-1 {
    width: 100%;
    display: block;
    margin-bottom: 100px;
  }
  .section-5 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 30px;
    margin-top: 40px;

    & > .item {
      background-color: #fff;
      box-shadow: 8px 8px 20px 0px rgba(51, 51, 51, 0.15);
      .top {
        height: 460px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 40px;
        h3 {
          font-size: 24px;
          color: #181a1b;
          margin-bottom: 24px;
          line-height: 1;
          font-weight: lighter;
        }
        .subtitle {
          font-size: 14px;
          color: #323436;
          margin-bottom: 90px;
          line-height: 1;
        }
        .intro {
          font-size: 18px;
          color: #323436;
          line-height: 46px;
          text-align: center;
          padding: 0;
          margin: 0;
        }
      }
      .tags {
        padding: 40px;
        box-sizing: border-box;
        .title {
          font-size: 18px;
          color: rgba(#323436, 0.5);
          line-height: 1;
          display: block;
          margin-bottom: 30px;
        }
        .list {
          padding: 0;
          margin: 0;
          .item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #323436;
            &:not(:last-child) {
              margin-bottom: 30px;
            }
            .iconfont {
            }
          }
        }
      }

      &:nth-child(1) {
        .top {
          background-color: rgba(#6190e8, 0.5);
        }
      }
      &:nth-child(2) {
        .top {
          background-color: rgba(#ff7e5f, 0.5);
        }
      }
      &:nth-child(3) {
        .top {
          background-color: rgba(#0edee3, 0.5);
        }
      }
    }
  }
  .p-2 {
    font-size: 14px;
    color: #323436;
    text-align: center;
    padding: 0;
    margin: 90px 0 60px;
  }
  .advisory {
    width: 100vw;
    height: 360px;
    margin-left: 50%;
    transform: translateX(-50%);
    background-color: #333333;

    .advisory-content {
      width: 1080px;
      height: 100%;
      margin-left: 50%;
      transform: translateX(-50%);
      padding-top: 100px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;

      .icon-box {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        border: solid 3px #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        .icon-dianhua {
          color: #fff;
          font-size: 70px;
        }
      }
      .info {
        margin: 0 280px 0 50px;
        h3 {
          font-size: 36px;
          line-height: 1;
          margin: 0;
          margin-bottom: 40px;
          font-weight: normal;
          color: #fff;
        }
        p {
          font-size: 18px;
          line-height: 36px;
          color: #fff;
          margin: 0;
        }
      }
      .weixin {
        .qrcode {
          width: 123px;
          height: 123px;
          display: block;
          margin-bottom: 24px;
        }
        .tip {
          font-size: 18px;
          color: #fff;
          text-align: center;
          margin: 0;
        }
      }
    }
  }
}
</style>